/** @jsx jsx */
import { jsx, Box, Container, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Slider from "react-slick"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  PrevArrow,
  NextArrow,
  Logos,
  SpecialistsListDir,
  VideoPlayer,
  CallToActionSchedule,
  AccordionGroup,
} from "../components/elements"

const Service = ({ data, pageContext }) => {
  const page = data.datoCmsChildrensService
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Hero>
        <Container
          sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
        >
          <Styled.h1>{page.title}</Styled.h1>
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel={page.title}
          />
        </Container>
        <Overlay sx={{ opacity: 1 }} />

        <ColorBar
          sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 20 }}
        />
      </Hero>

      <Container sx={{ px: "1em", py: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{ paddingRight: [0, 0, 3], width: ["full", "full", "70%"] }}
          ></Box>
          <Box sx={{ width: ["full", "full", "30%"], mb: [0, 0, 3] }}>
            <CallToActionSchedule />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {/* Sidebar */}
          <Box
            as="aside"
            id="sidebar"
            sx={{
              bg: ["transparent", "transparent", "warmgray"],
              flexBasis: "sidebar",
            }}
            data-datocms-noindex
            width={["full"]}
          >
            <Box sx={{ px: [0, 0, 4], py: 4 }}>
              {/* Logos */}
              {page.logos && <Logos logos={page.logos} />}

              {page.sidebarContentNode && (
                <Text
                  sx={{
                    width: ["full"],
                    mb: [3],
                    ul: {
                      listStyle: "none",
                      margin: 0,
                      padding: 0,
                    },
                    li: {
                      borderBottomColor: "gray.5",
                      borderBottomStyle: "solid",
                      borderBottomWidth: 1,
                      py: 1,
                    },
                    "li a": {
                      textDecoration: "none",
                    },
                    "li a:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: page.sidebarContentNode.childMarkdownRemark.html,
                  }}
                />
              )}

              {page.findADocSpecialtyId && (
                <SpecialistsListDir id={page.findADocSpecialtyId} />
              )}
            </Box>
          </Box>

          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}

            {/* Content */}
            <Box as="section" id="content">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={{ width: ["full"], pl: [0, 0, 4] }}>
                  {page.video && (
                    <Box sx={{ mt: -3, mb: 4 }}>
                      <VideoPlayer url={page.video.url} />
                    </Box>
                  )}

                  <Text
                    sx={{ width: ["full"] }}
                    dangerouslySetInnerHTML={{
                      __html: page.descriptionNode.childMarkdownRemark.html,
                    }}
                  />

                  {page?.accordionGroup && (
                    <AccordionGroup accordionItems={page?.accordionGroup} />
                  )}

                  {page.image.length > 0 ? (
                    <Box sx={{ mb: 5 }}>
                      <Slider {...settings}>
                        {page.image.map(({ fluid }) => (
                          <Img fluid={fluid} alt={page.title} />
                        ))}
                      </Slider>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default Service

export const query = graphql`
  query serviceQuery($slug: String!) {
    datoCmsChildrensService(slug: { eq: $slug }) {
      id
      findADocSpecialtyId
      slug
      title
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      accordionGroup {
        id
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      sidebarContentNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      meta {
        status
      }
      video {
        url
      }
      logos {
        url
        logo {
          alt
          fluid(maxWidth: 250, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        id
      }
      seoMetaTags {
        tags
      }
    }
    datoCmsSpecialtyCare {
      servicesPagesHero {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
